import {Box, Button, Divider, Grid, MenuItem, TextField, Tooltip, Typography, useTheme} from '@mui/material';
import React, {useContext, useEffect, useState} from 'react';
import {UserAccountContext} from '../../../../providers/UserAccountProvider';
import {useTranslation} from 'react-i18next';
import {AuthContext, IAuthContext} from 'react-oauth2-code-pkce';
import {ImpersonateUserContext} from '../../../../providers/ImpersonateUserProvider';
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import dayjs, {Dayjs} from "dayjs";
import useAccountApi from "../../../../hooks/account/Account";
import {GeneralSettingsContext} from "../../../../providers/GeneralSettingsProvider";
interface AccountSettingsPageProps {
}

export default function AccountSettingsPage(props: AccountSettingsPageProps) {
    const {t} = useTranslation();
    const theme = useTheme();
    const auth: IAuthContext = useContext(AuthContext)
    const {impersonateUsername} = useContext(ImpersonateUserContext);
    const {getAccountGeneralSettings, updateAccountGeneralSettings} = useAccountApi();
    const {user, setUser} = useContext(UserAccountContext)
    const {generalSettings, reloadGeneralSettings} = useContext(GeneralSettingsContext);
    const [twentyFourHourEnabled, setTwentyFourHourEnabled] = useState<boolean>(true);

    const [editMode, setEditMode] = useState<boolean>(false);
    const [timeErrorMf, setTimeErrorMf] = useState(false);
    const [timeErrorSat, setTimeErrorSat] = useState(false);
    const [timeErrorSun, setTimeErrorSun] = useState(false);

    const [mondayToFridayStart, setMondayToFridayStart] = useState<Dayjs | null>(generalSettings?.hoursMfStart || dayjs().startOf('date'));
    const [mondayToFridayEnd, setMondayToFridayEnd] = useState<Dayjs | null>(generalSettings?.hoursMfEnd || dayjs().startOf('date'));
    const [saturdayStart, setSaturdayStart] = useState<Dayjs | null>(generalSettings?.hoursSatStart || dayjs().startOf('date'));
    const [saturdayEnd, setSaturdayEnd] = useState<Dayjs | null>(generalSettings?.hoursSatEnd || dayjs().startOf('date'));
    const [sundayStart, setSundayStart] = useState<Dayjs | null>(generalSettings?.hoursSunStart || dayjs().startOf('date'));
    const [sundayEnd, setSundayEnd] = useState<Dayjs | null>(generalSettings?.hoursSunEnd || dayjs().startOf('date'));
    const [useMetric] = useState<boolean | null>(generalSettings?.useMetric || true);


    async function handleUpdate() {
        if (mondayToFridayStart && mondayToFridayEnd && saturdayStart && saturdayEnd && sundayStart && sundayEnd) {
            try {
                let generalSettings = await updateAccountGeneralSettings({
                    hoursMfFrom: mondayToFridayStart.format('HH:mm:ss'),
                    hoursMfTo: mondayToFridayEnd.format('HH:mm:ss'),
                    hoursSatFrom: saturdayStart.format('HH:mm:ss'),
                    hoursSatTo: saturdayEnd.format('HH:mm:ss'),
                    hoursSunFrom: sundayStart.format('HH:mm:ss'),
                    hoursSunTo: sundayEnd.format('HH:mm:ss'),
                    useMetric: useMetric
                });

                if (!user) {
                    auth.logOut();
                }
                setEditMode(false);
                reloadGeneralSettings();
            } catch (error) {
                console.error("Failed to update account settings. Logging out. Error: " + error);
                auth.logOut();
            }
        }
    }

    function discardChanges() {
        setMondayToFridayStart(generalSettings?.hoursMfStart || dayjs().startOf('date'));
        setMondayToFridayEnd(generalSettings?.hoursMfEnd || dayjs().startOf('date'));
        setSaturdayStart(generalSettings?.hoursSatStart || dayjs().startOf('date'));
        setSaturdayEnd(generalSettings?.hoursSatEnd || dayjs().startOf('date'));
        setSundayStart(generalSettings?.hoursSunStart || dayjs().startOf('date'));
        setSundayEnd(generalSettings?.hoursSunEnd || dayjs().startOf('date'));
        setEditMode(false);
        setTimeErrorMf(false);
        setTimeErrorSat(false);
        setTimeErrorSun(false);
    }



    return (
        <Box sx={{
            display: 'flex', flexDirection: "column", flexGrow: 1, justifyContent: "space-between"
        }}>
            <Box sx={{
                padding: '30px',
                paddingTop: '10px',
            }}>
                <Typography variant="header" sx={{paddingBottom: "30px"}}>
                    {t('profile_page.account_settings.title')}
                </Typography>
                <Divider/>
                <Grid container padding="10px" spacing={2.5}>
                    <Grid item xs={12} md={4} lg={4}>
                        <Typography variant="title">
                            {t('profile_page.account_settings.business_hours.title')}
                        </Typography>
                        <Typography variant="body">
                            {t('profile_page.account_settings.business_hours.description')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={8} lg={8}>
                        <Typography variant="title">
                            {t('profile_page.account_settings.business_hours.monday_to_friday')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TimePicker
                                    ampm={!twentyFourHourEnabled}
                                    label={t('profile_page.account_settings.business_hours.start_time')}
                                    value={mondayToFridayStart}
                                    disabled={!editMode}
                                    onChange={(newValue) => {
                                        if (newValue && mondayToFridayEnd) {
                                            setMondayToFridayStart(newValue);
                                            setTimeErrorMf(newValue >= mondayToFridayEnd);
                                        }
                                    }}
                                />
                                {timeErrorMf && (
                                    <Typography variant="body2" color="error">
                                        {t('profile_page.account_settings.business_hours.invalid_range')}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TimePicker
                                    ampm={!twentyFourHourEnabled}
                                    label={t('profile_page.account_settings.business_hours.end_time')}
                                    value={mondayToFridayEnd}
                                    disabled={!editMode}
                                    onChange={(newValue) => {
                                        if (mondayToFridayStart && newValue) {
                                            setMondayToFridayEnd(newValue);
                                            setTimeErrorMf(mondayToFridayStart >= newValue);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Typography variant="title">
                            {t('profile_page.account_settings.business_hours.saturday')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TimePicker
                                    ampm={!twentyFourHourEnabled}
                                    label={t('profile_page.account_settings.business_hours.start_time')}
                                    value={saturdayStart}
                                    disabled={!editMode}
                                    onChange={(newValue) => {
                                        if (newValue && saturdayEnd) {
                                            setSaturdayStart(newValue);
                                            setTimeErrorSat(newValue >= saturdayEnd);
                                        }
                                    }}
                                />
                                {timeErrorSat && (
                                    <Typography variant="body2" color="error">
                                        {t('profile_page.account_settings.business_hours.invalid_range')}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TimePicker
                                    ampm={!twentyFourHourEnabled}
                                    label={t('profile_page.account_settings.business_hours.end_time')}
                                    value={saturdayEnd}
                                    disabled={!editMode}
                                    onChange={(newValue) => {
                                        if (saturdayStart && newValue) {
                                            setSaturdayEnd(newValue);
                                            setTimeErrorSat(saturdayStart >= newValue);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Typography variant="title">
                            {t('profile_page.account_settings.business_hours.sunday')}
                        </Typography>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TimePicker
                                    ampm={!twentyFourHourEnabled}
                                    label={t('profile_page.account_settings.business_hours.start_time')}
                                    value={sundayStart}
                                    disabled={!editMode}
                                    onChange={(newValue) => {
                                        if (newValue && sundayEnd) {
                                            setSundayStart(newValue);
                                            setTimeErrorSun(newValue >= sundayEnd);
                                        }
                                    }}
                                />
                                {timeErrorSun && (
                                    <Typography variant="body2" color="error">
                                        {t('profile_page.account_settings.business_hours.invalid_range')}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <TimePicker
                                    ampm={!twentyFourHourEnabled}
                                    label={t('profile_page.account_settings.business_hours.end_time')}
                                    value={sundayEnd}
                                    disabled={!editMode}
                                    onChange={(newValue) => {
                                        if (sundayStart && newValue) {
                                            setSundayEnd(newValue);
                                            setTimeErrorSun(sundayStart >= newValue);
                                        }
                                    }}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>

            {editMode && (
                <Box
                    bottom="0px"
                    sx={{
                        padding: '10px',
                        borderTop: '1px solid #808080',
                        display: 'flex', flexDirection: "row", justifyContent: "space-between"
                    }}>
                    <Button
                        variant="contained"
                        size='small'
                        color="secondary"
                        onClick={() => {
                            discardChanges()
                        }}>
                        {t('button.cancel')}
                    </Button>
                    <Button
                        variant="contained"
                        size='small'
                        color="primary"
                        disabled={timeErrorMf || timeErrorSat || timeErrorSun}
                        onClick={async () => {
                            await handleUpdate();
                        }}
                    >
                        {t('button.save')}
                    </Button>
                </Box>)}

            {!editMode && (
                <Box
                    bottom="0px"
                    sx={{
                        padding: '10px',
                        borderTop: '1px solid ' + theme.palette.contentPage.footerDivider,
                        display: 'flex', flexDirection: "row", justifyContent: "space-between"
                    }}>
                    <Box/>
                    <Button
                        variant="contained"
                        size='small'
                        color="primary"
                        onClick={() => {
                            setEditMode(true);
                        }}>
                        {t('button.edit')}
                    </Button>
                </Box>)
            }
        </Box>
    );
};





