import {useContext, useEffect, useState} from "react";
import {UserLocation} from "./dto/UserLocation";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import usePlatformApiFetch from "../shared/PlatformApiFetch";

interface UserLocationApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useUserLocationApi(props: UserLocationApiOptions = {}) {
    const auth: IAuthContext = useContext(AuthContext);
    const {platformApiFetch, checkOk} = usePlatformApiFetch();

    const [userLocations, setUserLocations] = useState<UserLocation[]>([]);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllUserLocations = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformApiFetch("tracking/v1/user-locations", 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(userLocations => {
                    setUserLocations(userLocations)
                    setLoading(false)
                    resolve(userLocations)
                })
                .catch((error) => {
                    console.error("Error getting user locations: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllUserLocations();
            if (props.shouldPoll) {
                const interval = setInterval(getAllUserLocations, props.pollFrequency ?? 30000);
                return () => clearInterval(interval);
            }
        }
    }, [auth.token]);

    return {
        loading, error, userLocations, getAllUserLocations
    }
}
