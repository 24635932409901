import { Asset, AssetAssignee, AssetEvent, AssetLocation, AssetUnit, Coordinates, GeofenceStatus } from "./dto/Asset"
import { Journey } from "./dto/Journey"
import { TimelineBlock } from "./dto/TimelineBlock"


export function mapToAssetsArray(json: any) {

    return json.map(mapToAsset);
}

export function mapToAsset(json: any) {
        return new Asset({
            id: json.id,
            type: json.type,
            make: json.make,
            model: json.model,
            vehicleClass: json.vehicleClass,
            colour: json.colour,
            year: json.year,
            assignee: mapAssetAssignee(json),
            identificationMark: json.identificationMark,
            groupName: json.groupName,
            alias: json.alias,
            status: json.status,
            latestEvent: json.latestEvent ? mapAssetEvent(json.latestEvent) : undefined,
            unit: mapAssetUnit(json.unit),
            assetSettings: json.assetSettings,
            notes: json.notes
        })
}

export function mapAssetAssignee(asset: any) {
    return new AssetAssignee({
        name: asset.assignee.name,
        mobileNumber: asset.assignee.mobileNumber
    })
}

export function mapAssetEvent(event: any) {
    return new AssetEvent({
        type: event.type,
        status: event.status,
        time: new Date(event.time),
        location: new AssetLocation({
            goodGpsFix: event.location.goodGpsFix,
            formattedAddress: event.location.formattedAddress,
            rawCoordinates: new Coordinates({
                latitude: event.location.rawCoordinates.latitude,
                longitude: event.location.rawCoordinates.longitude
            }),
            snappedCoordinates: new Coordinates({
                latitude: event.location.snappedCoordinates.latitude,
                longitude: event.location.snappedCoordinates.longitude
            }),
            zoneName: event.location.zoneName,
            geofences: mapGeofence(event.location.geofences)
        }),
        heading: event.heading,
        headingIndicator: event.headingIndicator,
        speed: event.speed,
        speedLimit: event.speedLimit,
        odometer: event.odometer,
        customEventName: event.customEventName,
        customEventId: event.customEventId,
        fuelLevel: event.fuelLevel,
        wasteLevel: event.wasteLevel,
        batteryPercentage: event.batteryPercentage,
        temperature1: event.temperature1,
        temperature2: event.temperature2
    })
}

export function mapAssetUnit(unit: any) {
    if (!unit) return undefined;
    return new AssetUnit({
        type: unit.type,
        model: unit.model,
        imei: unit.imei,
        simNumber: unit.simNumber,
        voiceNumber: unit.voiceNumber,
        dataNumber: unit.dataNumber
    })
}

export function mapGeofence(geofences: any): GeofenceStatus[] {
    return geofences.map((geofence: { geofenceName: any; enterTime: any; }) => {
        return new GeofenceStatus({
            geofenceName: geofence.geofenceName,
            enterTime: geofence.enterTime
        });
    });
}


export function mapToEventsArray(json: any) {
    return json.map(mapAssetEvent)
}

export function mapToJourneyObjects(json: any) {
    return json.map((journey: any) => {
        return new Journey({
            id: journey.id,
            distance: journey.distance,
            startAddress: journey.startAddress,
            endAddress: journey.endAddress,
            startTime: new Date(journey.startTime),
            endTime: new Date(journey.endTime)
        })
    })
}

export function mapToTimelineObjects(json: any) {
    return json.map((object: any) => {
        return new TimelineBlock({
            status: object.status,
            startTime: new Date(object.startTime),
            endTime: new Date(object.endTime),
            startLocation: object.startLocation,
            endLocation: object.endLocation
        })
    })
}
