import {useContext, useEffect, useState} from "react";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import usePlatformApiFetch from "../shared/PlatformApiFetch";

export class WasteAlert {
    id: number;
    accountId: number;
    threshold: number;
    recipients: string[];
    vehicles: string[];

    constructor(props: {
        id: number;
        accountId: number;
        threshold: number;
        recipients: string[];
        vehicles: string[];
    }) {
        this.id = props.id;
        this.accountId = props.accountId;
        this.threshold = props.threshold;
        this.recipients = props.recipients;
        this.vehicles = props.vehicles;
    }
}

export class WasteAlertRequest {
    threshold: number;
    recipientIds: number[];
    vehicleIds: number[];

    constructor(props: {
        threshold: number;
        recipientIds: number[];
        vehicleIds: number[];
    }) {
        this.threshold = props.threshold;
        this.recipientIds = props.recipientIds;
        this.vehicleIds = props.vehicleIds;
    }
}

interface WasteAlertsApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useWasteAlertsApi(props: WasteAlertsApiOptions = {}) {
    const auth: IAuthContext = useContext(AuthContext);
    const {platformApiFetch, checkOk, checkCreated} = usePlatformApiFetch();

    const [wasteAlerts, setWasteAlerts] = useState<WasteAlert[] | null>(null);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllWasteAlerts = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformApiFetch("tracking/v1/alerts/waste-alerts/", 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(alerts => {
                    setWasteAlerts(alerts)
                    resolve(alerts)
                })
                .catch((error) => {
                    console.error("Error getting waste alerts: " + error)
                    setError(error)
                    reject(error)
                })
                .finally(() => {
                    setLoading(false)
                });
        });
    }

    const createWasteAlert = async (wasteAlertRequest: WasteAlertRequest) => {
        return new Promise((resolve, reject) => {
            platformApiFetch("tracking/v1/alerts/waste-alerts", 'post', auth.token, JSON.stringify(wasteAlertRequest))
                .then(checkCreated)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error) => {
                    console.error("Error creating waste alerts: " + error)
                    reject(error)
                });
        });
    }

    const updateWasteAlert = async (wasteAlertRequest: WasteAlertRequest, wasteAlertId: string) => {
        return new Promise((resolve, reject) => {
            platformApiFetch(`tracking/v1/alerts/waste-alerts/${wasteAlertId}`, 'put', auth.token, JSON.stringify(wasteAlertRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(alert => resolve(alert))
                .catch((error) => {
                    console.error("Error updating waste alerts: " + error)
                    reject(error)
                });
        });
    }

    const deleteWasteAlert = async (wasteAlertId: string) => {
        return new Promise<void>((resolve, reject) => {
            platformApiFetch(`tracking/v1/alerts/waste-alerts/${wasteAlertId}`, 'delete', auth.token)
                .then(checkOk)
                .then(alert => resolve())
                .catch((error) => {
                    console.error("Error deleting waste alerts: " + error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllWasteAlerts();
            if (props.shouldPoll) {
                const interval = setInterval(getAllWasteAlerts, props.pollFrequency || 30000);
                return () => clearInterval(interval);
            }
        }
    }, [auth.token]);

    return {loading, error, wasteAlerts: wasteAlerts, getAllWasteAlerts, createWasteAlert, updateWasteAlert, deleteWasteAlert}
}
