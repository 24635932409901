import { Box, Divider, styled, Typography } from "@mui/material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { useTranslation } from "react-i18next";
import { TimelineBlock } from "../../../hooks/assets/dto/TimelineBlock";
import { useContext } from "react";
import { UserPreferencesContext } from "../../../providers/UserPreferencesProvider";
import { getStatusColors } from "../../../themes/AppTheme";

const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: '#f5f5f9',
        color: 'rgba(0, 0, 0, 0.87)',
        maxWidth: 300,
        fontSize: theme.typography.pxToRem(12),
        border: '1px solid #dadde9',
    },
}));

interface TimelineChartProps {
    data?: Array<TimelineBlock>;
}

export default function TimelineChart(props: TimelineChartProps) {
    const { colorPalette } = useContext(UserPreferencesContext)
    const statusColors = getStatusColors(colorPalette);
    const { t } = useTranslation();

    function renderTimelineBlocks() {

        if (!props.data || props.data.length === 0) {
            return (
                <Box sx={{
                    height: '40px',
                    width: '100%',
                    textAlign: 'center',
                    paddingTop: '7px',
                    backgroundColor: "lightgray",
                    borderRadius: '10px',
                }}> {props.data ? t('timeline.no_data') : t('timeline.loading')}</Box>)
        } else {
            return (<>{
                props.data.map((block, index) => {

                    const isFirstElement = index === 0;
                    const isLastElement = index === props.data!.length - 1;

                    let borderRadius = '0px';

                    if (isFirstElement) {
                        borderRadius = '10px 0px 0px 10px';
                    } else if (isLastElement) {
                        borderRadius = '0px 10px 10px 0px';
                    }


                    return (
                        <HtmlTooltip
                            title={
                                <>
                                    <Typography
                                        sx={{ display: "block", width: "100%" }}
                                        component="span"
                                        variant="cardtitle"
                                    >
                                        {getStatusText(block.status)}
                                    </Typography>
                                    {block.status === "MOVING" ?


                                        <table>
                                            <tr>
                                                <th style={{ padding: '5px' }}>{t('timeline.card.time')}</th>
                                                <th style={{ padding: '5px' }}>{t('timeline.card.location')}</th>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '5px' }}>{block.startTime.toLocaleTimeString()}</td>
                                                <td style={{ padding: '5px' }}>{block.startLocation}</td>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '5px' }}>{block.endTime.toLocaleTimeString()}</td>
                                                <td style={{ padding: '5px' }}>{block.endLocation}</td>
                                            </tr>
                                        </table>
                                        :
                                        <table>
                                            <tr>
                                                <th style={{ padding: '5px' }}>{t('timeline.card.from')}</th>
                                                <th style={{ padding: '5px' }}>{t('timeline.card.to')}</th>
                                                <th style={{ padding: '5px' }}>{t('timeline.card.location')}</th>
                                            </tr>
                                            <tr>
                                                <td style={{ padding: '5px' }}>{block.startTime.toLocaleTimeString()}</td>
                                                <td style={{ padding: '5px' }}>{block.endTime.toLocaleTimeString()}</td>
                                                <td style={{ padding: '5px' }}>{block.startLocation}</td>
                                            </tr>
                                        </table>
                                    }
                                </>
                            }
                        ><Box sx={{
                            borderRadius: borderRadius,
                            height: '40px',
                            width: calculateBlockWidth(block.startTime, block.endTime) + '%',
                            backgroundColor: selectBlockColor(block.status),
                        }}></Box></HtmlTooltip>)
                })
            }</>)
        }
    }

    function getStatusText(status: string) {
        switch (status) {
            case "STATIONARY": return t('timeline.card.status_label_stationary');
            case "MOVING": return t('timeline.card.status_label_moving');
            case "STOPPED": return t('timeline.card.status_label_stopped');
            case "PRIVATE": return t('timeline.card.status_label_private');
            default: return t('timeline.card.status_label_stopped');
        }
    }

    function calculateBlockWidth(startDateTime: Date, endDateTime: Date) {
        let start = startDateTime.getTime();
        let end = endDateTime.getTime();
        let total = end - start;
        let percentage = (total / 86400000) * 100;
        return percentage;
    }

    function selectBlockColor(status: string) {
        switch (status) {
            case "STATIONARY": return statusColors.stationary;
            case "MOVING": return statusColors.moving;
            case "STOPPED": return 'red';
            case "PRIVATE": return statusColors.private;
            default: return 'lightgrey';
        }
    }

    return (<>
        <Box sx={{
            paddingTop: '5px',
            paddingBottom: '5px',
            paddingLeft: '15px',
            paddingRight: '15px',
            height: '50px',
            width: '100%',
            display: 'flex',
            flexDirection: 'row',
        }}>
            {renderTimelineBlocks()}
        </Box>
        <Divider />
    </>
    );
}
