import {useContext, useEffect, useState} from "react";
import {ServiceRecordRequest} from "./dto/ServiceRecordRequest";
import {ServiceRecord} from "./dto/ServiceRecord";
import {AuthContext, IAuthContext} from "react-oauth2-code-pkce";
import usePlatformApiFetch from "../../shared/PlatformApiFetch";

interface ServiceRecordApiOptions {
    shouldLoadAll?: boolean;
    shouldPoll?: boolean;
    pollFrequency?: number;
}

export function useServiceRecordApi(props: ServiceRecordApiOptions = {}) {
    const auth: IAuthContext = useContext(AuthContext);
    const {platformApiFetch, checkOk, checkCreated, checkNoContent} = usePlatformApiFetch();

    const [serviceRecords, setServiceRecords] = useState<ServiceRecord[]>([]);
    const [error, setError] = useState<string | undefined>(undefined);
    const [loading, setLoading] = useState(false);

    const getAllServiceRecords = async () => {
        return new Promise((resolve, reject) => {
            setLoading(true)
            platformApiFetch("tracking/v1/servicing/records", 'get', auth.token)
                .then(checkOk)
                .then(response => response.json())
                .then(serviceRecords => {
                    setServiceRecords(serviceRecords)
                    setLoading(false)
                    resolve(serviceRecords)
                })
                .catch((error) => {
                    console.error("Error getting service records: " + error)
                    setError(error)
                    setLoading(false)
                    reject(error)
                });
        });
    }

    const createServiceRecord = async (serviceRecordRequest: ServiceRecordRequest) => {
        return new Promise((resolve, reject) => {
            platformApiFetch(`tracking/v1/servicing/${serviceRecordRequest.vehicleId}/records`, 'post', auth.token, JSON.stringify(serviceRecordRequest))
                .then(checkCreated)
                .then(response => response.json())
                .then(serviceRecord => resolve(serviceRecord))
                .catch((error) => {
                    console.error("Error creating service record: " + error)
                    reject(error)
                });
        });
    }

    const updateServiceRecord = async (serviceRecordRequest: ServiceRecordRequest, recordId: string, vehicleId: number) => {
        return new Promise((resolve, reject) => {
            platformApiFetch(`tracking/v1/servicing/${vehicleId}/records/${recordId}`, 'put', auth.token, JSON.stringify(serviceRecordRequest))
                .then(checkOk)
                .then(response => response.json())
                .then(serviceRecord => resolve(serviceRecord))
                .catch((error) => {
                    console.error("Error updating service record: " + error)
                    reject(error)
                });
        });
    }

    const deleteServiceRecord = async (assetId: string, recordId: string) => {
        return new Promise<void>((resolve, reject) => {
            platformApiFetch(`tracking/v1/servicing/${assetId}/records/${recordId}`, 'delete', auth.token)
                .then(checkNoContent)
                .then(() => resolve())
                .catch((error) => {
                    console.error("Error deleting service record: " + error)
                    reject(error)
                });
        });
    }

    useEffect(() => {
        if (props.shouldLoadAll) {
            getAllServiceRecords();
            if (props.shouldPoll) {
                const interval = setInterval(getAllServiceRecords, props.pollFrequency ?? 30000);
                return () => clearInterval(interval);
            }
        }
    }, [auth.token]);

    return {
        loading, error, serviceRecords, getAllServiceRecords, createServiceRecord, updateServiceRecord, deleteServiceRecord
    }
}
