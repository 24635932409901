import { Box, List } from "@mui/material";
import { Asset } from "../../../../hooks/assets/dto/Asset";
import AssetsListSection from "./AssetsListSection";

import React, { useContext } from "react";
import { Map } from "leaflet";
import AssetsFilterBar from "../../../../components/leftpanel/AssetFilterBar";
import { UserPreferencesContext } from "../../../../providers/UserPreferencesProvider";
import { getStatusColors } from "../../../../themes/AppTheme";
import { FilterSortContext } from "../../../../providers/FilterSortProvider";
import {AssetSortOptions, sortByAliasOrReg, sortByDriverName, sortByLastEventTime} from "../../../../utils/AssetArraySorting";
import { t } from "i18next";
import { filterAssetsBySearch } from "../../../../utils/AssetArrayFiltering";
import ReactGA from "react-ga4";
import { FilterAssetContext } from "../../../../providers/FilterAssetProvider";

interface AssetsViewProps {
    mapref: React.RefObject<Map>;
    assets: Array<Asset>;
}

export default function AssetsList(props: Readonly<AssetsViewProps>) {
    const { colorPalette } = useContext(UserPreferencesContext)
    const statusColors = getStatusColors(colorPalette);

    const [filter, setFilter] = React.useState<string | undefined>(undefined);
    const { sort, groupFilter, typeFilter } = useContext(FilterSortContext);
    const {  setFilteredAssets } = useContext(FilterAssetContext);

    React.useEffect(() => {
        const data = filterAssetsWithoutStatus(props.assets)
        if(data.length){
            setFilteredAssets(data);
        }
    }, [filter, sort, groupFilter, typeFilter]);


    const onSearchChange = (search: string) => {
        if (search === undefined || search === "") {
            setFilter(undefined);
        } else {
            ReactGA.event("search",  {search_term: "live-map-" + search});
            setFilter(search);
        }
    }

    const filterAssets = (assets: Array<Asset>, status: string) => {

        let filteredAssets = assets.filter(asset => asset.status === status)
        filteredAssets = filterAssetsBySearch(filteredAssets, filter);

        switch (sort) {
            case AssetSortOptions.DRIVER_NAME:
                filteredAssets.sort(sortByDriverName);
                break;
            case AssetSortOptions.LAST_REPORTED:
                filteredAssets.sort(sortByLastEventTime);
                break;
            default:
                assets.sort(sortByAliasOrReg);
                break;
        }

        if (groupFilter !== "all") {
            filteredAssets = filteredAssets.filter(asset => asset.groupName === groupFilter);
        }
        if (typeFilter !== "all") {
            filteredAssets = filteredAssets.filter(asset => asset.type === typeFilter);
        }
        return filteredAssets;
    }

    const filterAssetsWithoutStatus = (assets: Array<Asset>) => {

        assets = filterAssetsBySearch(assets, filter);

        switch (sort) {
            case AssetSortOptions.DRIVER_NAME:
                assets.sort(sortByDriverName);
                break;
            case AssetSortOptions.LAST_REPORTED:
                assets.sort(sortByLastEventTime);
                break;
            default:
                assets.sort(sortByAliasOrReg);
                break;
        }

        if (groupFilter !== "all") {
            assets = assets.filter(asset => asset.groupName === groupFilter);
        }
        if (typeFilter !== "all") {
            assets = assets.filter(asset => asset.type === typeFilter);
        }
        return assets;
    }

    return (
        <Box component="main" sx={{
            flexGrow: 1, p: 0,
            height: 'calc(-48px + 100vh)', overflow: "hidden",
            overflowY: "scroll",
        }}>
            <AssetsFilterBar onSearchChange={onSearchChange} assets={props.assets} />
            <List
                dense={true}
                sx={{ width: "100%", maxWidth: 360, bgcolor: "background.paper" }}
            >
                <AssetsListSection title={t('asset_status.moving')} titleColor={statusColors.moving} mapref={props.mapref} assets={filterAssets(props.assets, "MOVING")}/>
                <AssetsListSection title={t('asset_status.stationary')} titleColor={statusColors.stationary} mapref={props.mapref} assets={filterAssets(props.assets, "STATIONARY")} />
                <AssetsListSection title={t('asset_status.stopped')} titleColor={statusColors.stopped} mapref={props.mapref} assets={filterAssets(props.assets, "STOPPED")} />
                <AssetsListSection title={t('asset_status.inactive')} titleColor={statusColors.inactive} mapref={props.mapref} assets={filterAssets(props.assets, "INACTIVE")} />
            </List>
        </Box>
    );
}
