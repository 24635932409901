import {Box, Divider, ListItemButton, Typography} from '@mui/material';
import {Asset} from '../../../../hooks/assets/dto/Asset';
import PersonIcon from '@mui/icons-material/Person';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import NearMeIcon from '@mui/icons-material/NearMe';
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import {buildAssetStatusMessage, buildAssigneeName} from '../../../../utils/AssetUtils';
import {Map} from 'leaflet';
import {AssetCardIconText} from './AssetCardIconText';
import {AssetWarnMessage} from './AssetWarnMessage';
import {useTranslation} from 'react-i18next';
import * as React from "react";
import {useContext} from "react";
import {UserPreferencesContext} from "../../../../providers/UserPreferencesProvider";
import {useNavigate} from "react-router-dom";
import ReactGA from "react-ga4";

interface AssetCardProps {
    mapref: React.RefObject<Map>;
    asset: Asset;
}

function AssetCard(props: Readonly<AssetCardProps>) {
    const navigate = useNavigate();
    const {t} = useTranslation();
    const {twentyFourHourEnabled} = useContext(UserPreferencesContext)

    const handleListItemClick = (
        asset: Asset,
    ) => {
        ReactGA.event("RT2-LiveMap-SelectedVehicle");
        navigate(`/assets/${asset.id}`);
    };

    let geofenceName: string | undefined = undefined;
    if(props.asset.latestEvent?.location.geofences[0]) {
        const geofence = props.asset.latestEvent.location.geofences[0];
        if(geofence.geofenceName.toLowerCase() === "unknown") {
            geofenceName = t('asset_messages.unknown') ?? undefined;
        } else {
            geofenceName = `${geofence.geofenceName}, `;
        }
    }

    return (
        <>
            <ListItemButton alignItems="flex-start" onClick={(event) => handleListItemClick(props.asset)}>
                <Box>
                    <Typography
                        sx={{display: "block", width: "100%"}}
                        component="div"
                        variant="cardtitle"
                        color="text.primary"
                    >
                        {props.asset.alias || props.asset.identificationMark}
                    </Typography>
                    {props.asset.latestEvent?.location.goodGpsFix === false &&
                        <AssetWarnMessage text={t('asset_messages.poor_gps_fix')}/>}
                    <AssetCardIconText icon={<LocationOnIcon fontSize="inherit"/>} prefixValue={geofenceName}
                                       value={props.asset.latestEvent?.location.formattedAddress ?? ""}/>
                    <AssetCardIconText icon={<NearMeIcon fontSize="inherit"/>} value={buildAssetStatusMessage(props.asset, t)}/>
                    <AssetCardIconText icon={<PersonIcon fontSize="inherit"/>} value={buildAssigneeName(props.asset, t)}/>
                    {props.asset.groupName !== null &&
                        <AssetCardIconText icon={<WorkspacesIcon fontSize="inherit"/>} value={props.asset.groupName}/>}
                    <AssetCardIconText icon={<AccessTimeFilledIcon fontSize="inherit"/>}
                                       value={props.asset.latestEvent?.time.toLocaleString('en-GB', {
                                           year: 'numeric',
                                           month: "numeric",
                                           day: 'numeric',
                                           hour: "numeric",
                                           minute: "numeric",
                                           second: "numeric",
                                           hour12: !twentyFourHourEnabled
                                       }).toUpperCase() ?? ""}/>
                </Box>
            </ListItemButton>
            <Divider component="li"/>
        </>
    );
}

export default AssetCard;
