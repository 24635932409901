import dayjs, { Dayjs } from 'dayjs';

export class AccountGeneralSettings {
    hoursMfStart: Dayjs | null;
    hoursMfEnd: Dayjs | null;
    hoursSatStart: Dayjs | null;
    hoursSatEnd: Dayjs | null;
    hoursSunStart: Dayjs | null;
    hoursSunEnd: Dayjs | null;
    useMetric: boolean = true;

    constructor(props: {
        hoursMfStart: Dayjs | null;
        hoursMfEnd: Dayjs | null;
        hoursSatStart: Dayjs | null;
        hoursSatEnd: Dayjs | null;
        hoursSunStart: Dayjs | null;
        hoursSunEnd: Dayjs | null;
        useMetric?: boolean;
    }) {
        this.hoursMfStart = props.hoursMfStart;
        this.hoursMfEnd = props.hoursMfEnd;
        this.hoursSatStart = props.hoursSatStart;
        this.hoursSatEnd = props.hoursSatEnd;
        this.hoursSunStart = props.hoursSunStart;
        this.hoursSunEnd = props.hoursSunEnd;
        this.useMetric = props.useMetric !== undefined ? props.useMetric : true;
    }
}