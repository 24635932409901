import {Box} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {Map} from "leaflet";
import {useAssetApi} from "../../hooks/assets/Assets";
import AssetDetail from "./components/AssetDetail";
import {useNavigate, useParams} from 'react-router-dom';
import {Asset} from "../../hooks/assets/dto/Asset";
import BaseMap from "../../components/map/BaseMap";
import LoadingSpinner from "../../components/loadingspinner/LoadingSpinner";
import {useDebounce} from "use-debounce";


export default function AssetPage(this: any) {
    const { id } = useParams();
    const navigate = useNavigate();

    const {getAsset} = useAssetApi({shouldLoadAll: false, shouldPoll: false});
    const [asset, setAsset] = useState<Asset | undefined>(undefined);
    const [debouncedAsset] = useDebounce(asset, 1000);

    useEffect(() => {
        const loadData = ()=>{
            if (id) {
                getAsset(id).then((data) => {
                    setAsset(data);
                });
            }
        }

        loadData()
        const interval = setInterval(() => loadData(), 30000);
        return () => clearInterval(interval);
    }, [id]);

    const mapRef = useRef<Map>(null);

    return (
        <>
            <Box sx={{minWidth: "350px", padding: "0"}}>
                {!debouncedAsset && <Box sx={{ textAlign: "center", padding: "20px" }}><LoadingSpinner /></Box>}
                {debouncedAsset && (<AssetDetail asset={debouncedAsset} onBackPressed={()=>{navigate(-1)}}/>)}
            </Box>
            <BaseMap
                mapRef={mapRef}
                controls={{
                    keepInView: true,
                    labels: true,
                    clustering: false,
                    congestionZones:true,
                    geofences:true
                }}
                assets={asset ? [asset] : []}
            />

        </>
    );
}
